var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("img", {
              staticStyle: { "max-height": "150px" },
              attrs: { src: _vm.$root.$data.portalInfo.logoMain }
            })
          ])
        ],
        1
      ),
      !_vm.success
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("h1", [_vm._v("Authentication Required")]),
                  _c("page-snippet", {
                    staticClass: "mb-3",
                    attrs: { name: "authentication-required-info" }
                  }),
                  _c("h5", [_vm._v("Contract Info")]),
                  _vm.company
                    ? _c("div", { staticClass: "mb-3" }, [
                        _vm._v(" " + _vm._s(_vm.company.CompanyName)),
                        _c("br"),
                        _vm._v(
                          " " + _vm._s(_vm.company.StatementAddress.Street1)
                        ),
                        _vm.company.StatementAddress.Street1
                          ? _c("br")
                          : _vm._e(),
                        _vm._v(
                          " " + _vm._s(_vm.company.StatementAddress.Street2)
                        ),
                        _vm.company.StatementAddress.Street2
                          ? _c("br")
                          : _vm._e(),
                        _vm._v(
                          " " + _vm._s(_vm.company.StatementAddress.Street2)
                        ),
                        _vm.company.StatementAddress.Street3
                          ? _c("br")
                          : _vm._e(),
                        _vm._v(
                          " " + _vm._s(_vm.company.StatementAddress.PostalCode)
                        ),
                        _vm.company.StatementAddress.PostalCode
                          ? _c("br")
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.company.StatementAddress.City)),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.company.StatementAddress.Country.Description
                            )
                        ),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm.saving
                    ? _c("b-spinner", { attrs: { label: "Spinning" } })
                    : _vm._e(),
                  !_vm.saving
                    ? _c(
                        "b-form",
                        [
                          _c("h5", [_vm._v("Please fill out the form below")]),
                          _c("form-field", {
                            attrs: { form: _vm.form, name: "SigneeID" },
                            on: { change: _vm.formChange }
                          }),
                          _c("form-field", {
                            attrs: { form: _vm.form, name: "ProofOfAddress" },
                            on: { change: _vm.formChange }
                          }),
                          _c("form-field", {
                            attrs: { form: _vm.form, name: "PaymentMethod" },
                            on: { change: _vm.formChange }
                          }),
                          _vm.form.values.PaymentMethod ==
                          "671169A9-B6A1-5742-BA8E-2B159C83732E"
                            ? [
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "PaypalAccount"
                                  },
                                  on: { change: _vm.formChange }
                                })
                              ]
                            : _vm._e(),
                          _vm.form.values.PaymentMethod ==
                          "2613B69D-98AC-4B7C-9372-5A6F091365DD"
                            ? [
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_AccountName"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: { form: _vm.form, name: "Bank_IBAN" },
                                  on: { change: _vm.formChange }
                                })
                              ]
                            : _vm._e(),
                          _vm.form.values.PaymentMethod ==
                          "5F424FE2-0BD9-486D-B874-7605FCA2D5F5"
                            ? [
                                _c("form-field", {
                                  attrs: { form: _vm.form, name: "Bank_Name" },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_AccountName"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_AccountNumber"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_Address_BIC_SWIFT"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_Address"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_Address_Postcode"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_Address_City"
                                  },
                                  on: { change: _vm.formChange }
                                }),
                                _c("form-field", {
                                  attrs: {
                                    form: _vm.form,
                                    name: "Bank_Address_Country"
                                  },
                                  on: { change: _vm.formChange }
                                })
                              ]
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                disabled: !_vm.readyToProceed
                              },
                              on: { click: _vm.onSubmit }
                            },
                            [_vm._v("Save and proceed")]
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.success
        ? _c(
            "b-row",
            { staticClass: "mt-3 mb-3" },
            [
              _c(
                "b-col",
                [
                  _c("page-snippet", {
                    staticClass: "mb-3",
                    attrs: { name: "authentication-required-skip-instructions" }
                  }),
                  _c("b-form-input", {
                    model: {
                      value: _vm.skipTest,
                      callback: function($$v) {
                        _vm.skipTest = $$v
                      },
                      expression: "skipTest"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.success
        ? _c(
            "b-row",
            { staticClass: "mt-3 mb-3" },
            [
              _c(
                "b-col",
                [
                  _c("h1", [_vm._v("Authentication Submitted")]),
                  _c("page-snippet", {
                    staticClass: "mb-3",
                    attrs: { name: "authentication-submitted" }
                  }),
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.proceed()
                        }
                      }
                    },
                    [_vm._v("Proceed")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }