<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <img style="max-height: 150px;" :src="$root.$data.portalInfo.logoMain" />
      </b-col>
    </b-row>

    <b-row v-if="!success">
      <b-col>
        <h1>Authentication Required</h1>

        <page-snippet class="mb-3" name="authentication-required-info" />

        <h5>Contract Info</h5>
        <div v-if="company" class="mb-3">
          {{ company.CompanyName }}<br/>
          {{ company.StatementAddress.Street1 }}<br v-if="company.StatementAddress.Street1" />
          {{ company.StatementAddress.Street2 }}<br v-if="company.StatementAddress.Street2" />
          {{ company.StatementAddress.Street2 }}<br v-if="company.StatementAddress.Street3" />
          {{ company.StatementAddress.PostalCode }}<br v-if="company.StatementAddress.PostalCode" />
          {{ company.StatementAddress.City }}<br/>
          {{ company.StatementAddress.Country.Description }}<br/>
        </div>

        <b-spinner v-if="saving" label="Spinning"></b-spinner>

        <b-form v-if="!saving">
          <h5>Please fill out the form below</h5>
          <form-field :form="form" name="SigneeID" @change="formChange" />
          <form-field :form="form" name="ProofOfAddress" @change="formChange" />
          <form-field :form="form" name="PaymentMethod" @change="formChange" />

          <template v-if="form.values.PaymentMethod == '671169A9-B6A1-5742-BA8E-2B159C83732E'">
            <form-field :form="form" name="PaypalAccount" @change="formChange" />

          </template>
          
          <template v-if="form.values.PaymentMethod == '2613B69D-98AC-4B7C-9372-5A6F091365DD'">
            <form-field :form="form" name="Bank_AccountName" @change="formChange" />
            <form-field :form="form" name="Bank_IBAN" @change="formChange" />
          </template>
          
          <template v-if="form.values.PaymentMethod == '5F424FE2-0BD9-486D-B874-7605FCA2D5F5'">
            <form-field :form="form" name="Bank_Name" @change="formChange" />
            <form-field :form="form" name="Bank_AccountName" @change="formChange" />
            <form-field :form="form" name="Bank_AccountNumber" @change="formChange" />
            <form-field :form="form" name="Bank_Address_BIC_SWIFT" @change="formChange" />
            <form-field :form="form" name="Bank_Address" @change="formChange" />
            <form-field :form="form" name="Bank_Address_Postcode" @change="formChange" />
            <form-field :form="form" name="Bank_Address_City" @change="formChange" />
            <form-field :form="form" name="Bank_Address_Country" @change="formChange" />
          </template>

          <b-button  variant="primary" @click="onSubmit" :disabled="!readyToProceed">Save and proceed</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-3" v-if="!success">
      <b-col>
        <page-snippet class="mb-3" name="authentication-required-skip-instructions" />
        <b-form-input v-model="skipTest"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-3" v-if="success">
      <b-col>
        <h1>Authentication Submitted</h1>
        <page-snippet class="mb-3" name="authentication-submitted" />

        <b-button @click="proceed()">Proceed</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { checkIban } from '../functions'

  export default {
    components: { 
      'page-snippet': () => import('@/components/PageSnippet'),
      'form-field': () => import('@/components/FormFieldV3'),
    },
    data () {
      return {
        saving: false,
        success: false,
        skipTest: '',
        company: null,
        form: {
          values: {},
          fields: {
            PaymentMethod: {
              label: 'Payment Method',
              placeholder: 'Please select payment method',
              required: true,
              type: 'select',
              options: {
                '671169A9-B6A1-5742-BA8E-2B159C83732E': 'Paypal',
                '2613B69D-98AC-4B7C-9372-5A6F091365DD': 'IBAN',
                '5F424FE2-0BD9-486D-B874-7605FCA2D5F5': 'International Bank Transfer (outside SEPA zone)',
              }
            },
            PaypalAccount: { 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_IBAN:{ 
              type: 'text',
              label: '',
              required: true,
              validation: function(value) { return checkIban(value) }
            },
            Bank_Name:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_AccountName:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_AccountNumber:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_Address_BIC_SWIFT:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_Address:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_Address_Postcode:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_Address_City:{ 
              type: 'text',
              label: '',
              required: true,
            },
            Bank_Address_Country:{ 
              type: 'text',
              label: '',
              required: true,
            },
            SigneeID:{ 
              type: 'file',
              label: 'Proof of Identity',
              required: true,
              description: 'Passport or other government issued Photo ID'
            },
            ProofOfAddress:{ 
              type: 'file',
              label: 'Proof of Address',
              required: true,
              description: 'Government or bank mail adressed to the address used for the contract. (See above)',
            },
          }
        }
      }
    },
    name: 'AuthenticationRequiredView',
    props: {
      providerId: String
    },
    watch: {
      skipTest () {
        if (this.skipTest.toLowerCase() == 'proceed') {
          this.proceed()
        }
      },
    },
    methods: {
      proceed () {
        this.$root.$data.hideMenu = false
        this.$router.push({ name: 'dashboard' })
      },
      formChange (value, fieldName) {
        this.$set(this.form.values, fieldName, value)
      },
      onSubmit() {
        this.saving = true

        let formData = new FormData()
        for (const [k, v] of Object.entries(this.form.values)) {
          formData.append(k, v)
        }

        this.$http.post('providers/'+this.providerId+'/authorization-info', 
          formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          this.saving = false
          this.success = true
        }).catch(() => {
          this.saving = false
          this.$notify({
              type: 'error',
              text: 'An error occurred while saving. Please refresh the page and try again.'
          });
        })
      },
    },
    computed: {
      readyToProceed () {
        const vals = this.form.values
        if (!vals.SigneeID) return false
        if (!vals.ProofOfAddress) return false
        if (!vals.PaymentMethod) return false

        if (vals.PaymentMethod == 'Paypal') {
          if (!vals.PaypalAccount) return false
        } else if (vals.PaymentMethod == 'IBAN') {
          if (!vals.Bank_AccountName) return false
          if (!checkIban(vals.Bank_IBAN)) return false
        } else if (vals.PaymentMethod == 'International') {
          if (!vals.Bank_Name) return false
          if (!vals.Bank_AccountName) return false
          if (!vals.Bank_AccountNumber) return false
          if (!vals.Bank_Address_BIC_SWIFT) return false
          if (!vals.Bank_Address) return false
          if (!vals.Bank_Address_Postcode) return false
          if (!vals.Bank_Address_City) return false
          if (!vals.Bank_Address_Country) return false
        }
        
        return true
      },
    },
    mounted () {

      // Get Provider Adress
      this.$http.get('providers/'+this.providerId+'/company')
        .then(response => {
          this.company = response.data
        })
        .catch(() => {
          this.$notify({text: 'Unable to fetch Provider Address'})
        })
    }
  }
</script>